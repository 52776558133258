import get from 'lodash.get';

import birdsImage from '@assets/images/2_birds.png';

export const error404Transform = (query) =>  {
  const queryData = query.nodeQuery.entities[0];

  const data = {
    title: get(queryData, 'entityLabel', ''),
    uuid: get(queryData, 'uuid', ''),
    metaData: {
      title: get(queryData, 'fieldMetaTitle', ''),
      description: get(queryData, 'fieldMetatags.entity.fieldDescription', ''),
      keywords: get(queryData, 'fieldMetatags.entity.fieldKeywords', ''),
      hreflang: get(queryData, 'fieldHreflang', []).map(item => ({
        code: get(item, 'entity.fieldLang'),
        url: get(item, 'entity.fieldHref.url.path')
      })),
    },
    banner: {
      image: {
        src: get(queryData, 'fieldBannerTop.entity.fieldBannerImage.url', ''),
        alt: get(queryData, 'fieldBannerTop.entity.fieldBannerImage.alt', ''),
        title: get(queryData, 'fieldBannerTop.entity.fieldBannerImage.title', ''),
      },
      imageMobile: {
        src: get(queryData, 'fieldBannerTop.entity.fieldBannerImageMobile.url', ''),
        alt: get(queryData, 'fieldBannerTop.entity.fieldBannerImageMobile.alt', ''),
        title: get(queryData, 'fieldBannerTop.entity.fieldBannerImageMobile.title', ''),
      },
    },
    content: get(queryData, 'fieldBannerTop.entity.fieldBannerText.processed', ''),
    contentImage: birdsImage
  };

  return data;
}